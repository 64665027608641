<template>
    <div class="fog-wrapper">
        <h2 class="fog_header">Reporting</h2>
        <div>
            <div class="fog_frame">
                <iframe :src="fogReportingURL" id="dataframe"
                width="100%" height="100%" target="_parent"
                frameborder="0" onload="FOGParentHelper.resizeFrame(this)">
                </iframe>
            </div>
            <div class="fog-spinner">
                <loader-component v-if="showLoader"></loader-component>
            </div>
            <notification-component
                v-if="showNotification"
                :notificationMessage="notificationMessage"
                :notificationType="notificationType"
                :keyType="notificationKey"
                :dismissDelay="5000"
                @close="showNotification = false"
            ></notification-component>
        </div>
    </div>
</template>

<script>
document.domain = 'morningstar.com';        
import $ from 'jquery';
import LoaderComponent from "../../ui_component/loaderComponent.vue";
import NotificationComponent from "../../ui_component/notificationComponent.vue";

export default {
    name: 'fog-reporting',
    data() {
        return {
            fogReportingURL: '',
            showLoader: true,
            showNotification: false,
            notificationMessage: "",
            notificationType: "informational",
            notificationKey: "informational-example"
        }
    },
    components: {
        LoaderComponent,
        NotificationComponent
    },
    mounted () {
        // console.log(process.env, 'process.env');
        this.fogReportingURL = process.env.VUE_APP_FOG_REPORTING;
        this.fogEvents();
    },
    methods: {
            fogEvents() {
                var $this = this;
                this.iframeId = '#dataframe';
                this.modelId = '#details-modal';
                this.iframeMinWidth = 1190;
                this.iframeMinHeight = 600;
                var msse = {
                    Loader: function(){}
                }
                msse.Loader.prototype.show = function()
                {
                    console.log("Loader Show!");
                    $this.showLoader = true;
                }
                msse.Loader.prototype.hide = function()
                {
                    console.log("Loader Hide!");
                    $this.showLoader = false;
                }
                window.FOGParentHelper = {
                    resizeFrame() {
                            console.log("resizeFrame!",);
                            var iframeLoader = this.Loader();
                            iframeLoader.show();
                            var $e = $('#dataframe');
                            var $content = $e.contents();
                            var $newWidth = $(window).width();
                            var $newHeight = $content.height();    
                            $newHeight = $content.find("body").prop("scrollHeight");    
                            // var tabPosition = screen.height-292;    
                            $e.height($newHeight);
                            //$e.height($newHeight > this.iframeMinHeight ? $newHeight : this.iframeMinHeight);
                            $e.width($newWidth > this.iframeMinWidth ? $newWidth : this.iframeMinWidth);
                            // console.log($newHeight)
                            // var iframe = document.getElementById('dataframe');
                            // var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                            var $tabs = $content.find("div.tabs");
                            if ($tabs && $tabs.length > 0) {
                                console.log($tabs, 56);
                                $tabs[0].style.cssText = "position:fixed; bottom:0";
                                // $tabs[0].style.cssText = `position:fixed; bottom:${tabPosition}`;
                            }
                            iframeLoader.hide();
                        },
                    ShowModalPopup(url, f) {
                            console.log(url, f, "ShowModalPopup!");
                        },
                    CloseModalPopup(url, f) {
                            console.log(url, f, "CloseModalPopup!");
                        },
                    ToastMessage(type, msg) {
                            console.log(type, msg, "ToastMessage!");
                            if (type === 'showSuccessToast') {
                                $this.notificationType = "success";
                                $this.notificationKey = "success-tinted";
                            } else if (type === 'showWarningToast') {
                                $this.notificationType = "warning"
                                $this.notificationKey = "warning-tinted";
                            } else if (type === 'showErrorToast') {
                                $this.notificationType = "error";
                                $this.notificationKey = "error-tinted";
                            } else {
                                $this.notificationType = "informational";
                                $this.notificationKey = "informational-example";
                            }
                            $this.showNotification = true;
                            $this.notificationMessage = msg;
                        },
                    Loader() {
                            return new msse.Loader();  
                        }  
                }
            }
        },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/styles.scss";

.fog-wrapper {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;

    .fog_header {
        margin: $mds-space-2-x 0;
        @include mds-level-2-heading($bold: false);
    }
    .fog_frame {
        min-height: 80vh;
    }
}

</style>